// src/pages/ContactPage.tsx
import React, { useState, FormEvent } from 'react'; // Correct import of FormEvent
import { Mail, Phone, MapPin } from 'lucide-react';
import './ContactPage.scss';

interface ContactFormData { // Renamed to avoid conflict with FormData
  name: string;
  email: string;
  phone: string;
  message: string;
}

const ContactPage = () => {
  const [formData, setFormData] = useState<ContactFormData>({
    name: '',
    email: '',
    phone: '',
    message: '',
  });

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    // Example form submission logic
    console.log('Form Submitted', formData);
    alert('Thank you for your message. We will get back to you soon!');

    // Reset form after submission
    setFormData({
      name: '',
      email: '',
      phone: '',
      message: '',
    });
  };

  return (
    <div className="contact-page">
      <div className="contact-container">
        <div className="contact-info animate-slide-in">
          <h2>Contact Pravin B Mahadik & Co.</h2>
          <div className="contact-details">
            <div className="contact-item">
              <Mail size={24} />
              <span>cmapravinmahadik@gmail.com</span>
            </div>
            <div className="contact-item">
              <Phone size={24} />
              <span>7507760006 | 020-24455006</span>
            </div>
            <div className="contact-item">
              <MapPin size={24} />
              <span>
                Flat No. 4, 2nd Floor, Omkar Apartment, Survey No. 275 A&B,
                Shukrawar Peth, Pune - 411002
              </span>
            </div>
          </div>
        </div>

        <form className="contact-form animate-fade-in" onSubmit={handleSubmit}>
          <h3>Send us a Message</h3>
          <div className="form-group">
            <label htmlFor="name">Full Name</label>
            <input
              type="text"
              id="name"
              name="name"
              value={formData.name}
              onChange={handleChange}
              required
            />
          </div>

          <div className="form-group">
            <label htmlFor="email">Email Address</label>
            <input
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
            />
          </div>

          <div className="form-group">
            <label htmlFor="phone">Phone Number</label>
            <input
              type="tel"
              id="phone"
              name="phone"
              value={formData.phone}
              onChange={handleChange}
            />
          </div>

          <div className="form-group">
            <label htmlFor="message">Your Message</label>
            <textarea
              id="message"
              name="message"
              value={formData.message}
              onChange={handleChange}
              required
              rows={4}
            ></textarea>
          </div>

          <button type="submit" className="submit-button">
            Send Message
          </button>
        </form>
      </div>
    </div>
  );
};

export default ContactPage;
