// src/data/services.ts
import { ServiceCategory } from '../types/Service';

export const SERVICES_CATEGORIES: ServiceCategory[] = [
  {
    id: 1,
    title: 'Cost Accounting & Cost Management Services',
    services: [
      {
        id: 'cost-analysis',
        name: 'Cost Analysis & Allocation',
        description: 'Comprehensive analysis of direct and indirect costs for accurate pricing.'
      },
      {
        id: 'cost-control',
        name: 'Cost Control',
        description: 'Implementing measures to monitor spending and prevent budget overruns.'
      },
      {
        id: 'budgeting',
        name: 'Budgeting & Forecasting',
        description: 'Strategic financial planning and forecasting for informed decision-making.'
      },
      {
        id: 'cost-sheet',
        name: 'Cost Sheet Preparation',
        description: 'Detailed insights into cost structures and profitability.'
      }
    ]
  },
  {
    id: 2,
    title: 'Cost Audit & Compliance',
    services: [
      {
        id: 'cost-audit',
        name: 'Cost Audit',
        description: 'Ensuring compliance with statutory requirements and regulatory standards.'
      },
      {
        id: 'cost-records',
        name: 'Maintenance of Cost Records',
        description: 'Accurate cost records for reporting and accountability.'
      },
      {
        id: 'statutory-compliance',
        name: 'Statutory Compliance',
        description: 'Staying compliant with cost accounting standards.'
      },
      {
        id: 'certification',
        name: 'Certification Services',
        description: 'Statutory certifications for cost records and audits.'
      }
    ]
  },
  {
    id: 3,
    title: 'Management Accounting & Financial Planning',
    services: [
      {
        id: 'variance-analysis',
        name: 'Variance Analysis',
        description: 'Identifying gaps between budgeted and actual performance.'
      },
      {
        id: 'balanced-scorecard',
        name: 'Balanced Scorecard & Strategic Planning',
        description: 'Supporting strategy execution and measurement.'
      },
      {
        id: 'financial-planning',
        name: 'Financial Planning & Analysis (FP&A)',
        description: 'Aligning financial resources with business goals.'
      },
      {
        id: 'kpi',
        name: 'Key Performance Indicators (KPIs)',
        description: 'Measuring and improving financial performance.'
      }
    ]
  },
  {
    id: 4,
    title: 'Inventory & Fixed Asset Management',
    services: [
      {
        id: 'perpetual-inventory',
        name: 'Perpetual Inventory (PI) Management',
        description: 'Ensuring inventory accuracy through regular counts.'
      },
      {
        id: 'fixed-asset-verification',
        name: 'Fixed Asset Verification (FAV)',
        description: 'Tagging and reconciling fixed assets.'
      },
      {
        id: 'inventory-valuation',
        name: 'Inventory Valuation',
        description: 'Accurate valuation for precise financial reporting.'
      },
      {
        id: 'supply-chain-analysis',
        name: 'Supply Chain Cost Analysis',
        description: 'Optimizing supply chain costs for efficiency.'
      }
    ]
  },
  {
    id: 5,
    title: 'Valuation & Business Advisory Services',
    services: [
      {
        id: 'business-valuation',
        name: 'Business Valuation',
        description: 'Credible valuations for companies and startups.'
      },
      {
        id: 'project-feasibility',
        name: 'Project Feasibility Analysis',
        description: 'Evaluating project profitability and viability.'
      },
      {
        id: 'cost-benefit-analysis',
        name: 'Cost-Benefit Analysis',
        description: 'Supporting high-return investments.'
      },
      {
        id: 'financial-due-diligence',
        name: 'Financial Due Diligence',
        description: 'Ensuring financial stability for acquisitions or investments.'
      }
    ]
  },
  {
    id: 6,
    title: 'Taxation & Compliance',
    services: [
      {
        id: 'gst-compliance',
        name: 'GST Compliance & Filing',
        description: 'Filing GST returns and summaries.'
      },
      {
        id: 'income-tax',
        name: 'Income Tax Return Filing',
        description: 'Accurate preparation and filing of returns.'
      },
      {
        id: 'tds-compliance',
        name: 'TDS Compliance',
        description: 'Filing TDS returns to ensure regulatory compliance.'
      }
    ]
  },
  {
    id: 7,
    title: 'Payroll & Reconciliation Services',
    services: [
      {
        id: 'payroll-processing',
        name: 'Payroll Processing',
        description: 'Comprehensive payroll processing and compliance.'
      },
      {
        id: 'reconciliation',
        name: 'Reconciliation Services',
        description: 'Detailed reconciliation of accounts for accuracy and transparency.'
      }
    ]
  },
  {
    id: 8,
    title: 'Specialized Costing Services',
    services: [
      {
        id: 'product-costing',
        name: 'Product Costing',
        description: 'Accurate costing for pricing strategies.'
      },
      {
        id: 'activity-based-costing',
        name: 'Activity-Based Costing (ABC)',
        description: 'Allocating costs based on activities.'
      },
      {
        id: 'profitability-analysis',
        name: 'Profitability Analysis',
        description: 'Assessing and improving profitability.'
      },
      {
        id: 'break-even-analysis',
        name: 'Break-even Analysis',
        description: 'Supporting sales and pricing strategies.'
      }
    ]
  },
  {
    id: 9,
    title: 'Forensic & Internal Audit Services',
    services: [
      {
        id: 'forensic-audits',
        name: 'Forensic Audits',
        description: 'Investigating fraud and financial discrepancies.'
      },
      {
        id: 'internal-audit',
        name: 'Internal Audit',
        description: 'Evaluating risk management and governance processes.'
      },
      {
        id: 'fraud-risk-assessment',
        name: 'Fraud Risk Assessment',
        description: 'Identifying and mitigating fraud risks.'
      },
      {
        id: 'process-improvement',
        name: 'Process Improvement Recommendations',
        description: 'Strengthening internal processes.'
      }
    ]
  }
];