import React from 'react';
import { Service } from '../../types/Service';
import { LucideCalculator, LucideShieldCheck } from 'lucide-react';
import './ServiceCard.scss';

const iconMap = {
  'calculator': LucideCalculator,
  'shield-check': LucideShieldCheck,
};

interface ServiceCardProps {
  service: Service; // Explicit type for service
}

const ServiceCard = ({ service }: ServiceCardProps) => {
  const IconComponent = iconMap[service.icon as keyof typeof iconMap] || LucideCalculator;

  return (
    <div className="service-card animate-fade-in">
      <div className="service-card__icon">
        <IconComponent size={48} />
      </div>
      <h3 className="service-card__title">{service.title}</h3>
      <p className="service-card__description">{service.description}</p>
    </div>
  );
};

export default ServiceCard;
