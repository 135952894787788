// src/pages/ServicesPage.tsx
import React, { useState } from 'react';
import { SERVICES_CATEGORIES } from '../data/services.ts';
import { ServiceCategory, ServiceDetail } from '../types/Service.ts';
import './ServicesPage.scss';

const ServiceDetailCard: React.FC<{ service: ServiceDetail }> = ({ service }) => (
  <div className="service-detail-card animate-fade-in">
    <h4>{service.name}</h4>
    <p>{service.description}</p>
  </div>
);

const ServicesPage: React.FC = () => {
  const [activeCategory, setActiveCategory] = useState<number | null>(SERVICES_CATEGORIES[0].id);

  const renderServiceCategory = (category: ServiceCategory) => (
    <div 
      key={category.id} 
      className={`service-category ${activeCategory === category.id ? 'active' : ''}`}
    >
      <h2>{category.title}</h2>
      <div className="service-details-grid">
        {category.services.map(service => (
          <ServiceDetailCard key={service.id} service={service} />
        ))}
      </div>
    </div>
  );

  return (
    <div className="services-page">
      <section className="services-hero">
        <h1>Our Comprehensive Services</h1>
        <p>Tailored financial solutions to drive your business forward</p>
      </section>

      <div className="services-content">
        <div className="services-navigation">
          {SERVICES_CATEGORIES.map(category => (
            <button 
              key={category.id}
              className={`category-tab ${activeCategory === category.id ? 'active' : ''}`}
              onClick={() => setActiveCategory(category.id)}
            >
              {category.title}
            </button>
          ))}
        </div>

        <div className="services-display">
          {activeCategory && 
            SERVICES_CATEGORIES.find(cat => cat.id === activeCategory) &&
            renderServiceCategory(
              SERVICES_CATEGORIES.find(cat => cat.id === activeCategory)!
            )
          }
        </div>
      </div>
    </div>
  );
};

export default ServicesPage;

// // src/pages/ServicesPage.tsx
// import React from 'react';
// import { SERVICES } from '../data/services.ts';
// import ServiceCard from '../components/Services/ServiceCard.tsx';
// import './ServicesPage.scss';

// const ServicesPage = () => {
//   return (
//     <div className="services-page">
//       <section className="services-hero">
//         <h1>Our Comprehensive Services</h1>
//         <p>Tailored financial solutions to drive your business forward</p>
//       </section>

//       <section className="services-grid">
//         {SERVICES.map(service => (
//           <ServiceCard key={service.id} service={service} />
//         ))}
//       </section>
//     </div>
//   );
// };

// export default ServicesPage;