// src/pages/HomePage.tsx
import React from 'react';
import { SERVICES_CATEGORIES } from '../data/services.ts';
import ServiceCard from '../components/Services/ServiceCard.tsx';
import { useNavigate } from 'react-router-dom';
import './HomePage.scss';

interface Testimonial {
  name: string;
  industry: string;
  quote: string;
}

const TESTIMONIALS: Testimonial[] = [
  {
    name: "Rajesh Patil",
    industry: "Manufacturing Industry",
    quote: "Pravin B Mahadik & Co. has been instrumental in streamlining our cost management processes. Their expertise and attention to detail have greatly improved our financial transparency."
  },
  {
    name: "Sneha Kulkarni",
    industry: "IT Sector",
    quote: "The team's personalized approach and in-depth knowledge have helped our business achieve better financial planning and compliance. Highly recommend their services!"
  },
  {
    name: "Amit Desai",
    industry: "Real Estate Developer",
    quote: "Their valuation services provided us with the confidence to make a critical acquisition decision. Truly professional and reliable!"
  }
];

const HomePage: React.FC = () => {
  const navigate = useNavigate();

  const handleNavigation = (path) => {
    navigate(`/${path}`);
  };

  return (
    <div className="home-page">
      <section className="hero animate-slide-in">
        <div className="hero__content">
          <h1>Pravin B Mahadik & Co.</h1>
          <h2>Cost Accountants</h2>
          <p>Empowering Businesses with Precision and Insight</p>
          <p className="hero__tagline">
            Behind Every Successful Business Decision, There is Always a CMA
          </p>
          <div className="hero__cta-buttons">
            <button className="cta-button primary" onClick={() => handleNavigation('services')}>Explore Services</button>
            <button className="cta-button secondary" onClick={() => handleNavigation('contact')}>Contact Us</button>
          </div>
        </div>
      </section>

      <section className="about-section">
        <div className="about-content">
          <h2>About Our Firm</h2>
          <p>
            At Pravin B Mahadik & Co., we believe sound financial management is the foundation of business success. 
            Recognized as one of Maharashtra's leading cost audit firms, we bring in-depth industry knowledge, 
            a commitment to precision, and a personalized approach that puts client needs first.
          </p>
          <div className="about-highlights">
            <div className="highlight">
              <h3>Personalized Attention</h3>
              <p>We offer a unique level of personal service, often found only in internal finance departments.</p>
            </div>
            <div className="highlight">
              <h3>Industry Expertise</h3>
              <p>Our team's experience spans multiple industries, including manufacturing, IT, and more.</p>
            </div>
            <div className="highlight">
              <h3>Commitment to Quality</h3>
              <p>We uphold the highest standards in every aspect of our work to ensure accuracy and reliability.</p>
            </div>
          </div>
        </div>
      </section>

      <section className="services-preview">
        <h2>Our Core Services</h2>
        <div className="services-grid">
          {SERVICES_CATEGORIES.slice(0, 4).map(service => (
            <ServiceCard key={service.id} service={service} />
          ))}
        </div>
      </section>

      <section className="industries-served">
        <h2>Industries We Serve</h2>
        <div className="industries-grid">
          <div className="industry-card">Information Technology & Software</div>
          <div className="industry-card">Real Estate & Infrastructure</div>
          <div className="industry-card">Engineering & Manufacturing</div>
          <div className="industry-card">Telecommunications</div>
          <div className="industry-card">Retail & Food Processing</div>
          <div className="industry-card">Travel & Hospitality</div>
        </div>
      </section>

      <section className="testimonials">
        <h2>What Our Clients Say</h2>
        <div className="testimonials-grid">
          {TESTIMONIALS.map((testimonial, index) => (
            <div key={index} className="testimonial-card">
              <p className="testimonial-quote">"{testimonial.quote}"</p>
              <div className="testimonial-author">
                <span className="name">{testimonial.name}</span>
                <span className="industry">{testimonial.industry}</span>
              </div>
            </div>
          ))}
        </div>
      </section>

      <section className="contact-cta">
        <div className="contact-content">
          <h2>Ready to Empower Your Business?</h2>
          <p>Get in touch with Pravin B Mahadik & Co. and take the first step towards financial excellence.</p>
          <div className="contact-details">
            <p>📞 7507760006 | 020-24455006</p>
            <p>✉️ cmapravinmahadik@gmail.com</p>
            <p>📍 Flat No. 4, 2nd Floor, Omkar Apartment, Survey No. 275 A&B, 
               Shukrawar Peth - Madhukar Chimanrav Potnis Road, 
               Near Chichechi Talim, Pune- 411002</p>
          </div>
          <button className="cta-button primary" onClick={() => handleNavigation('contact')}>Contact Us Today</button>
        </div>
      </section>
    </div>
  );
};

export default HomePage;

// // src/pages/HomePage.tsx
// import React from 'react';
// import { SERVICES_CATEGORIES } from '../data/services.ts';
// import ServiceCard from '../components/Services/ServiceCard.tsx';
// import './HomePage.scss';

// const HomePage = () => {
//   return (
//     <div className="home-page">
//       <section className="hero animate-slide-in">
//         <div className="hero__content">
//           <h1>Pravin B Mahadik & Co.</h1>
//           <h2>Cost Accountants</h2>
//           <p>Empowering Businesses with Precision and Insight</p>
//           <button className="cta-button">Explore Services</button>
//         </div>
//       </section>

//       <section className="services-preview">
//         <h2>Our Core Services</h2>
//         <div className="services-grid">
//           {SERVICES_CATEGORIES.slice(0, 4).map(service => (
//             <ServiceCard key={service.id} service={service} />
//           ))}
//         </div>
//       </section>
//     </div>
//   );
// };

// export default HomePage;