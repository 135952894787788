import React from 'react';
import { Link } from 'react-router-dom';
import { 
  Home, 
  Briefcase, 
  Info, 
  Mail, 
  ChevronRight 
} from 'lucide-react';
import './Sidebar.scss';

interface SidebarProps {
  isOpen: boolean;
  onClose: () => void;
}

const Sidebar: React.FC<SidebarProps> = ({ isOpen, onClose }) => {
  return (
    <div className={`sidebar ${isOpen ? 'sidebar-open' : ''}`}>
      <div className="sidebar-header">
        <h2>Menu</h2>
        <button className="close-btn" onClick={onClose}>
          <ChevronRight size={24} />
        </button>
      </div>
      
      <nav className="sidebar-nav">
        <Link to="/" className="sidebar-nav-item" onClick={onClose}>
          <Home size={20} />
          <span>Home</span>
        </Link>
        
        <Link to="/services" className="sidebar-nav-item" onClick={onClose}>
          <Briefcase size={20} />
          <span>Services</span>
        </Link>
        
        <Link to="/about" className="sidebar-nav-item" onClick={onClose}>
          <Info size={20} />
          <span>About Us</span>
        </Link>
        
        <Link to="/contact" className="sidebar-nav-item" onClick={onClose}>
          <Mail size={20} />
          <span>Contact</span>
        </Link>
      </nav>

      <div className="sidebar-footer">
        <p>© {new Date().getFullYear()} Pravin B Mahadik & Co.</p>
        <div className="contact-info">
          <p>Phone: 7507760006</p>
          <p>Email: cmapravinmahadik@gmail.com</p>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
