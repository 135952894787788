// src/pages/AboutPage.tsx
import React from 'react';
import { Target, Award, Users } from 'lucide-react';
import Leadership_PBM from '../assets/PravinMahadik.jpg';
import './AboutPage.scss';

const AboutPage = () => {
  return (
    <div className="about-page">
      <section className="about-hero animate-slide-in">
        <h1>About Pravin B Mahadik & Co.</h1>
        <p>Empowering Businesses with Financial Expertise and Precision</p>
      </section>

      <section className="about-content">
        <div className="mission-section animate-fade-in">
          <div className="mission-icon">
            <Target size={48} />
          </div>
          <h2>Our Mission</h2>
          <p>
            To provide financial guidance that enables our clients to make informed, 
            strategic decisions while achieving long-term financial sustainability. 
            We integrate Environment, Social, and Governance (ESG) values into our 
            operations to support holistic growth.
          </p>
        </div>

        <div className="values-section animate-fade-in">
          <div className="values-grid">
            <div className="value-card">
              <Award size={36} />
              <h3>Commitment to Quality</h3>
              <p>
                We uphold the highest standards in every aspect of our work, 
                ensuring accuracy, reliability, and excellence.
              </p>
            </div>

            <div className="value-card">
              <Users size={36} />
              <h3>Personalized Approach</h3>
              <p>
                We offer a unique level of personal service, tailoring our solutions 
                to meet the specific needs of each client.
              </p>
            </div>

            <div className="value-card">
              <Target size={36} />
              <h3>Strategic Insight</h3>
              <p>
                Our team provides deep industry expertise and strategic insights 
                to help businesses navigate complex financial landscapes.
              </p>
            </div>
          </div>
        </div>

        <div className="team-section animate-slide-in">
          <h2>Our Leadership</h2>
          <div className="team-grid">
            <div className="team-member">
              <img 
                src={Leadership_PBM}
                alt="Pravin B Mahadik" 
                className="team-photo" 
              />
              <h3>Pravin B Mahadik</h3>
              <p>Founder</p>
            </div>
            {/* Add more team members as needed */}
          </div>
        </div>
      </section>
    </div>
  );
};

export default AboutPage;