// src/components/Layout/Footer.tsx
import React from 'react';
import { Link } from 'react-router-dom';
import { 
  Mail, 
  Phone, 
  MapPin, 
  Facebook, 
  Twitter, 
  Linkedin 
} from 'lucide-react';
import './Footer.scss';

const Footer = () => {
  return (
    <footer className="main-footer">
      <div className="footer-container">
        <div className="footer-column">
          <h4>Quick Links</h4>
          <Link to="/">Home</Link>
          <Link to="/services">Services</Link>
          <Link to="/about">About Us</Link>
          <Link to="/contact">Contact</Link>
        </div>

        <div className="footer-column">
          <h4>Contact Info</h4>
          <div className="contact-info">
            <Mail size={20} />
            <span>cmapravinmahadik@gmail.com</span>
          </div>
          <div className="contact-info">
            <Phone size={20} />
            <span>7507760006 | 020-24455006</span>
          </div>
          <div className="contact-info">
            <MapPin size={20} />
            <span>Pune, Maharashtra, India</span>
          </div>
        </div>

        <div className="footer-column">
          <h4>Follow Us</h4>
          <div className="social-icons">
            <a href="#" target="_blank" rel="noopener noreferrer">
              <Facebook size={24} />
            </a>
            <a href="#" target="_blank" rel="noopener noreferrer">
              <Twitter size={24} />
            </a>
            <a href="#" target="_blank" rel="noopener noreferrer">
              <Linkedin size={24} />
            </a>
          </div>
        </div>
      </div>

      <div className="copyright">
        © {new Date().getFullYear()} Pravin B Mahadik & Co. All Rights Reserved.
      </div>
    </footer>
  );
};

export default Footer;