// src/components/Layout/Header.tsx
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Menu } from 'lucide-react';
import Sidebar from './Sidebar.tsx';
import CMALogo from '../../assets/CMA_Logo.jpg';
import './Header.scss';

const Header = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  return (
    <>
      <header className="main-header">
        <div className="header-container">
          <div className="logo">
            <Link to="/">
              <img src={CMALogo} alt="Pravin B Mahadik & Co. Logo" />
            </Link>
          </div>

          <nav className="main-nav">
            <Link to="/">Home</Link>
            <Link to="/services">Services</Link>
            <Link to="/about">About Us</Link>
            <Link to="/contact">Contact</Link>
          </nav>

          <div className="mobile-menu-toggle" onClick={toggleSidebar}>
            <Menu size={24} />
          </div>
        </div>
      </header>

      <Sidebar 
        isOpen={isSidebarOpen} 
        onClose={() => setIsSidebarOpen(false)} 
      />
    </>
  );
};

export default Header;